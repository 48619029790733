/* Set a baseline font size on the HTML element for better responsiveness */
html {
    font-size: 16px; /* You can adjust this as needed */
  }
  
  /* Ensure the container always takes the full height and width of the viewport */
  .container {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 90%; /* Use a percentage for width */
    max-width: 600px;
    background: #fff;
    padding-bottom: 30px;
    height: auto; /* Allow height to adapt to content */
    min-height: 90vh; /* Use a percentage for minimum height */
    box-sizing: border-box; /* Include padding in the width calculation */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
  }
  
  /* Make the header flexible and centered */
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
  }
  
  /* Use relative font size units for better responsiveness */
  .text {
    font-size: 2.5rem;
    font-weight: 700;
  }
  .sub-text {
    font-size: 1.6rem;
    font-weight: 500;
  }
  
  /* Ensure the underline always has a fixed size relative to the text */
  .underline {
    width: 61px;
    height: 6px;
    background: #1f0050;
    border-radius: 9px;
  }
  
  /* Make input and image responsive */
  .inputs {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;
    max-width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .input img {
    margin: 0 15px;
    max-width: 30px; /* Limit the image size */
  }
  
  .input input {
    flex: 1; /* Allow the input to expand */
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 1rem;
  }
  
  /* Make the "forgot password" section responsive */
  .forgot-password {
    padding-left: 20px;
    margin-top: 20px;
    font-size: 1rem;
  }
.forgot-password span{
  cursor: pointer; 
  color: #1f0084;

}
  
/* Ensure the submit container uses flex to center the button */
.submit-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
  }
  
  /* Center the "Login" text within the submit button */
  .submit {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 220px;
    height: 59px;
    color: #fff;
    background-color: #1f0050;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
  }
  
  